<template>
  <div>
    <a-page-header :title="$route.query.id?'修改':'新增拜访'" @back="() => $router.go(-1)" />

    <a-row>
      <a-col :span="12">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="拜访名称">
            <a-input v-model="addFromData.title" />
          </a-form-item>
           <a-form-item label="产品名称">
            <a-input v-model="addFromData.product" />
          </a-form-item>
         
            <a-form-item label="简介">
            <a-textarea
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-model="addFromData.desc"
            />
          </a-form-item>

           <a-form-item label="快捷反馈内容">
            <a-textarea
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-model="addFromData.back_content"
            />
            <div>多个以|分隔</div>
          </a-form-item>
       
        
        </a-form>
      </a-col>
      <a-col :span="4">
          

        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
             <a-form-item label="排序">
            <a-input-number
              :min="0"
              style="width:160px"
              v-model="addFromData.list_order"
            />
          </a-form-item>

          <a-form-item label="触达人数">
            <a-input-number
              style="width:160px"
              v-model="addFromData.send_count"
            />
          </a-form-item>
          <a-form-item label="反馈人数">
            <a-input-number
              style="width:160px"
              v-model="addFromData.back_count"
            />
          </a-form-item>
            <a-form-item label="状态">
            <a-radio-group v-model="addFromData.status">
              <a-radio :value="1">
                启用
              </a-radio>
              <a-radio :value="0">
                禁用
              </a-radio>
            </a-radio-group>
          </a-form-item>
        
         
        
        </a-form>
      </a-col>
      <a-col :span="8">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
              <a-form-item label="开始时间">
            <a-date-picker
              tyle="width:160px"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              :locale="locale"
              v-model="addFromData.start_time"
            />
          </a-form-item>
          <a-form-item label="结束时间">
            <a-date-picker
              tyle="width:160px"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              :locale="locale"
              v-model="addFromData.end_time"
            />
          </a-form-item>
        
          <a-form-item label="选择栏目">
            <GroupSelect
              @GroupSelect="GroupSelect"
              :selectId="addFromData.group_id"
              style="width:200px"
            ></GroupSelect>
          </a-form-item>
          <a-form-item label="选择企业">
            <CompanySelect
              style="width:200px"
              @CompanySelect="CompanySelect"
              :serchKeyword="addFromData.company_name"
              :selectId="addFromData.company_id"
            ></CompanySelect>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="16">
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
          <a-form-item label="内容">
            <UE
              :sentContent="addFromData.content"
              @getUeContent="getUeContent"
            ></UE>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="8">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <a-form-item label="封面图">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadImage"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                style="display:block;max-width:100px;max-height:100px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-item>
         
          <a-button type="primary" style="margin-left:150px" @click="handleOk">
            保存
          </a-button>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import UE from "@/components/UE/UE.vue";
import {
  uploadImage,
  visitDataSave,
  visitDetail,
} from "@/apis/index";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

export default {
  data() {
    return {
      locale,
      addFromData: {
        id:'',
        title:'',
        product:'',
        img_id:'',
        desc:'',
        content:'',
        status:1,
        company_id:undefined,
        group_id:undefined,
        send_count:'',
        back_count:'',
        back_content:'',
        list_order:'',
        start_time:'',
        end_time:''
      },
      uploadImage,
      loading: false,
      imageUrl: "",

    };
  },
  components: {
    UE,
  },
  computed: {
  },
  watch: {
  

  },
  created() {
    if (this.$route.query.id) {
      visitDetail({ id: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          this.addFromData = { ...res.data };
          if (res.data.company) {
            this.addFromData.company_name = res.data.company.company_name;
          } else {
            this.addFromData.company_id = undefined;
            this.addFromData.company_name = undefined;
          }

          if (res.data.start_time > 0) {
            this.addFromData.start_time = moment(
              Number(res.data.start_time + "000")
            ).format("YYYY-MM-DD");
          } else {
            this.addFromData.start_time = "";
          }

           if (res.data.end_time > 0) {
            this.addFromData.end_time = moment(
              Number(res.data.end_time + "000")
            ).format("YYYY-MM-DD");
          } else {
            this.addFromData.end_time = "";
          }

         
          this.imageUrl = res.data.img_url;
        }
      });
    }
  },
  methods: {
    CompanySelect(val) {
      this.addFromData.company_id = val;
    },
    GroupSelect(val) {
      this.addFromData.group_id = val;
    },
    getUeContent(val) {
      this.addFromData.content = val;
    },
    handleOk() {
      visitDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$router.go(-1);
          this.$message.success("保存成功");
        }
      });
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.imageUrl = info.file.response.data.url;
        this.addFromData.img_id = info.file.response.data.id;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped></style>
